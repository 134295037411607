import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import IconSelect from "./utilities/IconSelect";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";

const BenefitsList = ({
  benefitsObject,
  classNameTitle = "",
  classNameIcon = "",
  classNameListText = "",
  whitespaceList = "pre-line",
  isIconCentered = false,
  mobileIconTop= false,
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      {benefitsObject?.title && (
        <RenderMarkdown
          markdownContent={benefitsObject.title}
          className={`text-center mb-5 sm:mb-10 ${classNameTitle}`}
        />
      )}
      {benefitsObject.imageObject && <PreviewCompatibleImage imageObject={benefitsObject.imageObject} className="mx-auto w-1/2 max-w-[15rem] mb-5"/>}
      <ul className="flex flex-col max-w-2xl mx-auto space-y-5">
        {benefitsObject.list.map((el) => {
          return (
            <li className={`flex space-x-2 ${mobileIconTop && "flex-col sm:flex-row space-y-5 sm:space-y-0"}`}>
              <div className={`${isIconCentered && "flex justify-center items-center"}`}>
                <IconSelect
                  iconName={el.iconName}
                  className={`w-8 h-8 sm:w-10 sm:h-10 mx-auto ${classNameIcon}`}
                />
              </div>
              {/* w-5/6 to ensure that Icons have enough space to stay at the same size together */}
              <div className="my-auto xl:w-auto pt-1 sm:pt-1.5">
                <RenderMarkdown
                  markdownContent={el.description}
                  className={classNameListText}
                  isMarkdown={true}
                  whitespace={whitespaceList}
                />
              </div>
            </li>
          );
        })}
      </ul>
      {benefitsObject.postscript && (
        <RenderMarkdown
          markdownContent={benefitsObject.postscript}
          className="text-center mt-5 sm:mt-10"
        />
      )}
    </div>
  );
};

export default BenefitsList;
